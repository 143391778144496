import { DataSource } from "src/app/_Models/common/common.model";

export const PageSizeCommon = [10, 25, 50, 100];
export const NewOption: number = -11;//NEW option in dropdownlist. it skip validation.
export const ListSize: number = 10;
export const UtilizationChartMinValue: number = 0;
export const UtilizationChartMaxValue: number = 100;
export const CusDashboardProductNameTrim: number = 17;
export const CusDashboardProductLength: number = 5;
export const QuotationSummaryNameLength: number = 53;
export const QuotationSummaryNameFirstLineLength: number = 25;
export const ServiceDateLength: number = 22;
export const FBResultNAName: string = "N/A"
export const FBResultNotApplicableName: string = "Not Applicable"
export const MobileViewFilterCount: number = 1;
export const MobileViewFiltersCount: number = 2;
export const CountrySelectedFilterTextCount: number = 3;
export const SupplierNameTrim: number = 28;
export const CustomerContact: string = "Customer Contact"
export const SupplierContact: string = "Supplier Contact"
export const FactoryContact: string = "Factory Contact"
export const DefectDashboardCountryTextCount: number = 3;
export const DefectDashboardSupplierNameTrim: number = 28;
export const DefectDashboardDataLengthTrim: number = 5;
export const amCoreLicense = "CH238479116";
export const DefectDashboardParetoNameTrim: number = 18;
export const DashboardCountryDefectNameTrim: number = 14;
export const DashboardDefectNamecPopupTrim: number = 19;
export const QuantitativeDashboardProductLength: number = 4;
export const QuantitativeDashboardCountryNameTrim: number = 9;
export const NumberOne = 1;
export const DecMonth = 12;
export const YearCount = 2;
export const ZeroValue = 0;
export const ListLengthOne = 1;
export const ListLengthTwo = 2;
export const ListLengthThree = 3;
export const CSDashboardStatusTrimValue: number = 7;
export const CSDashboardOfficeTrimValue: number = 11;
export const CSDashboardServiceTypeTrimValue: number = 14;
export const DataLengthTrim: number = 5;
export const CustomerMandayKPITemplate = 42;
export const XeroKPIInvoiceTemplate = 44;
export const XeroKPIExpenseTemplate = 45;
export const KPIECITemplate = 39;
export const ARFollowUpReportKPITemplate = 46;
export const OutstandingInvoice = 55;
export const CSDashboardBookingVerified: string = 'verified';
export const CSDashboardBookingConfirmed: string = 'confirmed';
export const CSDashboardCreated: string = 'created';
export const CSDashboardQuotationSent: string = 'sent';
export const CSDashboardCustomerConfirmed: string = 'validated';
export const CSDashboardQuotationApproved: string = 'approved';
export const CSDashboardQuotationModify: string = 'modify';
export const Add: string = 'Add';
export const Customer: string = 'Customer';
export const Vendor: string = 'Supplier/Factory';
export const HumanResource: string = 'Human Resource';
export const DataManagement: string = 'Data Management';
export const AreaManagement: string = 'Area Management';
export const UserManagement: string = 'User Management';
export const Leave: string = 'Leave';
export const Holiday: string = 'Holiday';
export const ExpenseClaim: string = 'Expense Claim';
export const InspectionPlatform: string = 'Inspection platform';
export const MappedTo: string = 'mapped to';
//below are the DB id
export const ReportSendInEmailAttachment: number = 2;
export const EmailType: number = 1; //customer decision
export const EmailTypeReportSend: number = 2;
export const EmailTypeInvoiceSend: number = 3;
export const EmailSendToOrCCAPITeam: number = 4;
export const EmailSendToOrCCCustomerContact: number = 6;

export const BookingSummaryNameTrim: number = 20;
export const AuditDefaultOffice: number = 4; //location id from db
export const CustomerSearch: number = 38; // Right for customer summary page
export const barCodeCharacterLimit = 100;
export const Size = 'Size'
export const Description = 'Description'
export const Code = 'Code'
export const RequiredValue = 'Req. Value'
export const TolerancePlus = 'Tol +'
export const ToleranceMinus = 'Tol -'

export const FlashProcessAudit = 'Flash Process Audit'
export const Desc = 'desc'
export const Asc = 'asc'
export class EntPageFieldAccess {
  entPageFieldAccessEnum: EntPageFieldAccessEnum;
  name: string;
  isDisplay: boolean;
}

export enum EntPageFieldAccessEnum {
  Booking_Edit_POQuantity = 1,
  Booking_Edit_DisplayMaster = 2,
  Booking_Edit_DisplayChild = 3,
  Booking_Edit_EcoPack = 4,
  Booking_Edit_CustomerRefPo = 5,
  Customer_Product_SubCategory2 = 6,
  Booking_Edit_Product_SubCategory2 = 7,
  Booking_Edit_Picking = 8,
  Booking_Edit_Combine = 9,
  Booking_Edit_Product_Barcode = 10,
  Booking_Edit_Product_Factory_Reference = 11,
  Booking_Edit_First_Service_Date = 12,
  Schedule_Summary_Product_Ref = 13
}

/* export const EntPageFieldAccessList: Array<any> = [
  { "entPageFieldAccessEnum": EntPageFieldAccessEnum.ProductDescription,"name": }
]; */



export enum UserType {
  InternalUser = 1,
  Customer = 2,
  Supplier = 3,
  Factory = 4,
  OutSource = 5
}
export enum AuditStatus {
  Requested = 1,
  Confirmed = 2,
  Cancel = 4,
  Rescheduled = 3,
  Scheduled = 5,
  Audited = 6
}
export enum APIService {
  Inspection = 1,
  Audit = 2,
  Tcf = 3,
  LabTesting = 4
}

export enum EntityAccess {
  API = 1,
  SGT = 2,
  AQF = 3
}

export enum APIServiceEnum {
  Audit = 1,
  Inspection = 2,
  TCF = 3,
  LabTesting = 4
}
export enum BookingStatus {
  New = 0,
  Requested = 1,
  Confirmed = 2,
  Rescheduled = 3,
  Cancel = 4,
  Scheduled = 5,
  Inspected = 6,
  Validated = 7,
  Verified = 8,
  AllocateQC = 9,
  Hold = 10,
  ReportSent = 11
}

export enum FetchFBReportOption {
  NotValidated = 1,
  All = 2
}

export enum SearchType {
  BookingNo = 1,
  ReportNo = 2,
  PoNo = 3,
  ServiceDate = 4,
  ApplyDate = 5,
  CustomerBookingNo = 8,
  FirstServiceDate = 9,
  InvoiceNo = 10,
  InvoiceDate = 11,
  ComplaintDate = 12,
  SupplierName = 13,
  SupplierCode = 14,
  ClaimDate = 15,
  ClaimNo = 16,
  ProductId = 17,
  QuotationNo = 18,
  AuditNo = 19,
  ProductRefDesc = 20,
  FactoryRef = 21
}
export enum DefaultDateType {
  ServiceDate = 4
}
export enum DefaultUserType {
  CS = 2
}
export enum AdvanceSearchType {
  FactoryReference = 6,
  ProductName = 7
}
export enum AuditSearchRedirectPage {
  Edit = 1,
  Cancel = 2,
  Reschedule = 3,
  Report = 4,
  Schedule_Auditor = 5,
  ReBooking = 9
}

export enum BookingSearchRedirectPage {
  Edit = 1,
  Cancel = 2,
  Reschedule = 3,
  Report = 4,
  Schedule = 5,
  CombineProduct = 6,
  SplitBooking = 7,
  ReInspectionBooking = 8,
  ReBooking = 9,
  Quotation = 10,
  ViewBookingForQuotation = 11
}
export enum LabType {
  Lab = 1,
  Customer = 2
}
export enum Unit {
  Set = 1,
  Pcs = 4
}
export enum InspectionPageType {
  Boooking = 1,
  ReInspection = 2,
  ReBooking = 3,
  Quotation = 4
}
export enum Service {
  Inspection = 1,
  Audit = 2,
  TCF = 3,
  Lab = 4
}
export enum RightsEnum {
  BookingEdit = 1,
  CustomerProduct = 2,
  ScheduleSummary = 3
}
export enum Currency {
  USD = 156
}
export enum DynamicControlModuleType {
  InspectionBooking = 1,
  AuditBooking = 2
}
export enum ControlType {
  TextBox = 1,
  TextArea = 2,
  DropDown = 3,
  DatePicker = 4
}
export enum ControlAttribute {
  PlaceHolder = 1,
  Type = 2,
  MaxLength = 3,
  MinLength = 4,
  Disabled = 5,
  ReadOnly = 6,
  RequiredValidation = 7,
  EmailValidation = 8,
  Multiple = 9,
  IsCascading = 10,
  ParentDropDown = 11
}
export const searchtypelst = [
  { name: "Booking No", id: 1 },
  { name: "Report No", id: 2 },
  { name: "Customer Booking No", id: 8 },
  //{ name: "PO No", id: 3 },
];

export const datetypelst = [
  // { name: "Service Date", id: 4, shortName: "SD" },
  // { name: "Apply date", id: 5, shortName: "AD" },
  // { name: "First Service Date", id: 9, shortName: "FSD" }
  { name: "Service Date", id: 4 },
  { name: "Apply date", id: 5 },
  { name: "First Service Date", id: 9 }
];

export const staffUserTypes = [{
  name: 'Customer Service', id: 2
},
{
  name: 'Report Checker', id: 11
}]
export enum datetypes {
  FirstserviceDate = 9
}

export enum InspectionLocationEnum {
  Factory = 1,
  PlatForm = 2
}

export const scheduleDatetypelst = [
  { name: "Service Date", id: 4 },
  { name: "Apply Date", id: 5 },
  { name: "First Service Date", id: 9 }
];

export const emailSendSummaryDatetypelst = [
  { name: "Service Date", id: 4 }
];

export const tcfDatetypelst = [
  { name: "ETD Date", id: 7 },
  { name: "Expected Closing date", id: 8 },
  { name: "Expiry Date", id: 9 },
  { name: "Request Date", id: 10 }
];

//Advance Filter in Booking Summary
export const bookingAdvanceSearchtypelst = [
  { name: "Factory Ref", id: 6 },
  { name: "Bar Code", id: 10 }



];
export const cancelrescheduletime = [
  { name: "Less than 24 hours", id: 1 },
  { name: "24 hours to 48 hours", id: 2 },
  { name: "More than 48 hours", id: 3 }
];

export const usertypelst = [
  { name: "Internal User", id: 1 },
  { name: "Customer", id: 2 },
  { name: "Supplier", id: 3 },
  { name: "Factory", id: 4 },
  { name: "OutSource", id: 5 },
];

export const expenseEmployeeTypeList = [
  { name: "Internal User", id: 1 },
  { name: "OutSource", id: 2 },
];

export const bookingSearchtypelst = [
  { name: "Inspection No", id: 1, shortName: "IN#" },
  { name: "PO No", id: 3, shortName: "PO#" },
  { name: "Customer Booking No", id: 8, shortName: "CU#" },
  { name: "Product Reference", id: 17, shortName: "PR#" }
];

export const tcfSearchtypelst = [
  { name: "Barcode", id: 1 },
  { name: "Customer Reference", id: 2 },
  { name: "Po No", id: 3 },
  { name: "TCF No", id: 4 },
  { name: "Supplier Reference", id: 5 },
  { name: "Description", id: 6 }
];

export const tcfSearchtypelstNew = [
  { name: "Barcode", id: 1, shortName: "Barcode" },
  { name: "Customer Reference", id: 2, shortName: "Cu Ref" },
  { name: "Po No", id: 3, shortName: "PO#" },
  { name: "TCF No", id: 4, shortName: "TCF#" },
  { name: "Supplier Reference", id: 5, shortName: "Su Ref" },
  { name: "Description", id: 6, shortName: "Des" }
];


export const extrafeeSearchtypelst = [
  { name: "Booking #", id: 1 },
  { name: "Invoice #", id: 10 }
];
export const customerReportSearchtypelst = [
  { name: "Inspection No", id: 1 },
  { name: "Report No", id: 2 },
  { name: "Cus. booking#", id: 8 },
];
export const claimDatetypelst = [
  { name: "Service Date", id: 4 },
  { name: "Claim date", id: 15 },
];


export const quotationSearchTypeList = [
  { name: "Insp. No", id: 1 },
  { name: "Quotation No", id: 18 },
  { name: "Customer Booking No", id: 8 },
];

export const BookingTableScrollHeight: number = 468; // used for condition check to scroll apply
export const QuotationTableScrollHeight: number = 1000; // used for condition check to scroll apply
export const InspCertificatePopupBookingTableScrollHeight: number = 350; // used for condition check to scroll apply
export const InspCertificateProductDeleteCount: number = 1;
export const CombineBookingTableScrollHeight: number = 750; // used for condition check to scroll apply
//  only china we need to filter
export enum Country {
  China = 38,
  Vietnam = 198
}

// Default status list to check the limit -- here id refered from INSP_Status table
export const bookingStatusList = [
  { name: "Inspected", id: 6 },
  { name: "Validated", id: 7 },
  { name: "ReportSent", id: 11 }
];

//Schedule User Types
export enum ScheduleRole {
  QC = 4,
  AdditionalQC = 4,
  CS = 2
}

//to check the Header Limit in the Menu
export enum HeaderCount {
  count = 7
}
export const icsearchtypelst = [
  { name: "Inspection No", id: 1 },
  // { name: "PO No", id: 2 },
  { name: "IC No", id: 3 }
];
export enum EmailUserVerication {
  ScheduleQCEmail = 1,
  ReportFilledToReportChecker = 2
}

export enum HRProfileEnum {
  Auditor = 1,
  CS = 2,
  Quotation = 10,
  Inspector = 4,
  ReportChecker = 11
}

export enum AqlType {
  AQLI = 1,
  AQLII = 2,
  AQLIII = 3,
  AQLCustom = 8
}

export enum AqlCustomLevel {
  Automatic = 1,
  Manual = 2
}

export enum SampleType {
  Sample1 = 1,
  Sample2 = 2,
  Sample3 = 3,
  Sample5 = 4,
  Sample100 = 5,
  Other = 6
}

export enum RoleEnum {
  Cs = 1,
  Inspector = 2,
  Planning = 3,
  IT_Team = 4,
  Management = 5, // approver leave and expense (Manager)
  CSManagement = 6,
  ExpenseRoleAccess = 7, //expense finance team
  HumanResource = 10, //leave access
  LeaveHr = 18, // Hr Leave
  ExpenseClaimNotification = 19, // expense claim email access
  QuotationRequest = 20,
  QuotationManager = 21,
  QuotationConfirmation = 22,
  InspectionRequest = 23,
  InspectionConfirmed = 24,
  InspectionVerified = 25,
  InspectionScheduled = 26,
  QuotationSend = 27,
  EditInspectionCustomerDecision = 33,
  ViewInspectionCustomerDecision = 34,
  InspectionCertificate = 32, // issue ic
  ReportChecker = 35,
  Accounting = 12,
  TechnicalTeam = 8,
  OperationTeamManagement = 9,
  HRManagement = 11,
  KAM = 13,
  CEO = 14,
  SalesTeam = 15,
  Supplier = 30,
  Factory = 31,
  TCFSupplier = 39,
  TCFIT = 40,
  WorkLoadMatrix = 51,
  OutSourceAccountingRole = 48,
  TCFQuotationCreate = 59,
  TCFQuotationApprove = 60,
  Customer = 29,
  LABCustomer = 64,
  LABSupplier = 65,
  CancelInvoiceAfterGracePeriod = 66,
  PriceCard = 67,
  NewTcfUser = 69,
  NewTcfCustomer = 70,
  NewTcfSupplier = 71
}
export enum ICStatus {
  Canceled = 2
}
export class DateObject {
  public year: number;
  public month: number;
  public day: number;
}


export enum EmployeeType {
  Permanent = 1,
  Outsource = 2,
  Other = 3
}

export enum StaffType {
  Inspector = 1,
  ReportChecker = 2
}

export enum InspectedStatusList {
  Inspected = 6,
  ReportSent = 11,
  //Validated = 7
}

export enum CustomSampleType {
  OnePiece = 1,
  TwoPieces = 2,
  ThreePieces = 3,
  FivePieces = 4,
  HundredPercent = 5,
  Others = 6,
  TenPercent = 7,
  TwentyPercent = 8,
  ThirtyPercent = 9,
  FiftyPercent = 10
}

export const CustomTypePercentList =
  [
    { "id": CustomSampleType.TenPercent, "value": 10 },
    { "id": CustomSampleType.TwentyPercent, "value": 20 },
    { "id": CustomSampleType.ThirtyPercent, "value": 30 },
    { "id": CustomSampleType.FiftyPercent, "value": 50 },
    { "id": CustomSampleType.HundredPercent, "value": 100 },
  ];

export const CustomTypePiecesList =
  [
    { "id": CustomSampleType.OnePiece, "sampleSize": 1 },
    { "id": CustomSampleType.TwoPieces, "sampleSize": 2 },
    { "id": CustomSampleType.ThreePieces, "sampleSize": 3 },
    { "id": CustomSampleType.FivePieces, "sampleSize": 5 },
  ];

export enum QuantityType {
  OrderedQty = 1,
  InspectedQty = 2,
  PresentedQty = 3
}

export enum InspectionServiceType {
  Container = 8,
  FinalRandomInspection = 5,
  FinalRandomReInspection = 15,
  //SgtContainer = 49
}

export const ContainerServiceList = [InspectionServiceType.Container];

export const HRProfileUserConfig = [
  { name: "CS", id: 2 },
  { name: "ReportChecker", id: 11 },
  { name: "Planner", id: 3 },
  { name: "Quotation", id: 10 },
  { name: "Schduler", id: 12 },
  { name: "Finance", id: 5 }
];

export enum BillingMethod {
  ManDay = 1,
  Sampling = 2,
  PieceRate = 3,
  PerIntervention = 4
}

export enum EntityFeature {
  AutoExpense = 1,
  MandayComplex = 2,
  SamplingComplex = 3,
  PieceRateComplex = 4,
  PerInterventionComplex = 5,
  HideMonthlyInvoiceForOperationTeamInvoiceStatus = 8,
  SameDayReportCheckPoint = 21
}

export enum InterventionType {
  Range = 1,
  PerStyle = 2
}

export enum Url {
  CustomerContact = "cuscontactsearch/customer-contact-summary/",
  SupplierEdit = "supplieredit/edit-supplier/",
  EditBooking = "inspedit/edit-booking/",
  EditAudit = "auditedit/edit-audit/",
  EditInvoice = "editinvoice/edit-invoice/",
  UserProfile = "/userprofile/user-profile/",
  EditSchedule = "schedule/schedule-allocation/",
  EditTravelTariff = "edittraveltariff/schedule-allocation/",
  EditClaim = "editClaim/edit-claim/",
  EditExpenseClaim = "expenseclaim/expense-claim/",
  EditDataManagement = "datamanagement/dmedit/",
  CustomerProduct = "cusproductedit/edit-customer-product/",
  Customer = "cusedit/edit-customer/",
  EditTCFBooking = "tcfBooking/edit-tcf-booking/",
  TCFDetailNew = "tcfdetailnew/tcf-detail-new/",
}

export enum FileUploadResponseResult {
  Sucess = 1,
  Failure = 2,
  FileSizeExceed = 3
}

// when add the container, sync list with File Server container list, and after add herer
export enum FileContainerList {
  Products = 1,
  DevContainer = 2,
  InsepctionBooking = 3,
  Invoice = 4,
  Hr = 5,
  TcfProducts = 6,
  EmailSend = 7,
  Audit = 8,
  Expense = 9,
  Report = 10,
  QuotationPdf = 11,
  DataManagement = 12,
  Claim = 13,
  GapSupportingDocument = 14,
  InvoiceSend = 15,
  ScheduleJob = 16,
  TCFStandard = 17,
  CustomerDecision = 18
}

export const StandardDateFormat = "dd/MM/yyyy HH:mm:ss";
export const StandardOnlyDateFormat = "dd/MM/yyyy";

export enum PageTypeSummary {
  CustomerReport = 1,
  FullBridge = 2,
  FillingReview = 3
}

export enum MSChartFileType {
  MSChartExcel = 1,
  ProductRefPictures = 2,
  MSChartPdf = 3
}

export enum BillPaidBy {
  Customer = 1,
  Supplier = 2,
  Factory = 3
}

export enum SupplierType {
  Factory = 1,
  Supplier = 2
}

export const invoiceSearchtypelst = [
  { name: "Booking No", id: 1, shortName: "IN#" },
  { name: "Invoice No", id: 10, shortName: "INV#" }
];

export enum InvoiceStatus {
  Created = 1,
  Modified = 2,
  Approved = 3,
  Cancelled = 4,
  Sent = 5
}

export const invoicedatetypelst = [
  { name: "Service Date", id: 4 }
  , { name: "Invoice Date", id: 11 }
];
export const KPIPPTtypelst = [
  { name: "Service Date", id: 4 }
  //,{ name: "Invoice Date", id: 11 }
];
export const extrafeedatetypelst = [
  { name: "Service Date", id: 4 },
  { name: "Apply Date", id: 5 }
];
export enum InvoicePaymentStatus {
  NotPaid = 1,
  HalfPaid = 2,
  Paid = 3,
  WriteOff = 4,
  ChequeReceived = 5,
  Waived = 6
}

export enum PaymentStatusName {
  Paid = "Paid",
  NotPaid = "Not Paid"
}

export enum ExtraFeeStatus {
  Pending = 1,
  Invoiced = 2,
  Cancel = 3,
  Sent = 4
}

export enum InvoiceType {
  Monthly = 1,
  PreInvoice = 2
}

export enum CustomerDecisionSaveResponseResult {
  success = 1,
  fail = 2,
  noemailconfiguration = 3,
  noEmailSubjectConfiguration = 4,
  noEmailBodyConfiguration = 5,
  noEmailRecipientsConfiguration = 6,
  multipleRuleFound = 7,
  noroleconfiguration = 8
}

//defect dashboard starts
export const DefectNameList = [
  { name: "Critical", id: 1, checked: true },
  { name: "Major", id: 2, checked: true },
  { name: "Minor", id: 3, checked: true }
];

export const DefectCritical = "Critical";
export const DefectMajor = "Major";
export const DefectMinor = "Minor";

export const DefectCriticalId = 1;
export const DefectMajorId = 2;
export const DefectMinorId = 3;

//defect dashboard ends

export enum FbFetchStatusList {
  Inspected = 6,
  ReportSent = 11,
  AllocateQI = 9
}

export enum RescheduleReason {
  RequestedByCustomer = 5,
  RequestedBySupplier = 6,
  RequestedByFactory = 7
}

export const ITSupporEmail: string = "itsupport@api-hk.com";
export const ITSupportSGTEmail: string = "itsupport@sgtgroup.net";
export const LBLServiceDesk: string = "Service Desk";
export const WormsServicePortal: string = "https://wormsgroup.atlassian.net/servicedesk/customer/portals";


export enum QuotationStatus {
  Pending = 0,
  Created = 1,
  ManagerApproved = 2,
  Verified = 4,
  Canceled = 5,
  CustomerValidated = 7,
  SentToClient = 8,
  QuotationRejected = 9
}

export const QuotationStatusList = [
  { name: "Pending", id: 0 },
  { name: "Created", id: 1 },
  { name: "Man. Approved", id: 2 },
  { name: "Man. Rejected", id: 3 },
  { name: "Verified", id: 4 },
  { name: "Canceled", id: 5 },
  { name: "Cust. Rejected", id: 6 },
  { name: "Validated", id: 7 },
  { name: "Sent", id: 8 },
  { name: "AE Rejected", id: 9 },
];
// Default FB Report result list
export const fbReportResultList = [
  { name: "Fail", id: 2 },
  { name: "Pending", id: 3 }
];

export enum FbReportResultType {
  Pass = 1,
  Fail = 2,
  Pending = 3,
  Not_Applicable = 4,
  Missing = 5,
  Conformed = 6,
  Not_Conformed = 7,
  Delay = 8,
  Note = 9
}

//user for the search type textbox validation in the customer report page
export enum CustomerReportSearchType {
  BookingNo = 1,
  ReportNo = 2
}

export const CustomerDecisionDdl = [
  { name: "Given", id: 1 },
  { name: "Not Given", id: 2 }
]

export const APIResult = [

  { name: "All", id: 0 },
  { name: "Pass", id: 1 },
  { name: "Fail", id: 2 },
  { name: "Pending", id: 3 },
  { name: "Not_Applicable", id: 4 },
  { name: "Missing", id: 5 },
  { name: "Conformed", id: 6 },
  { name: "Not_Conformed", id: 7 },
  { name: "Delay", id: 8 },
  { name: "Note", id: 9 }
]


export const InspectedStatusData = [
  { name: "Inspected", id: 6 },
  { name: "ReportSent", id: 11 }
]

export enum FBStatus {
  ReportDraft = 13,
  ReportArchive = 14,
  ReportValidated = 15,
  ReportInValidated = 16
}
export enum StaffStatus {
  OnJob = 1,
  Retired = 2,
  Left = 3,
  Disabled = 4
}
export const HRStaffStatus = [
  { name: "On-job", id: 1, color: "greencolor" },
  { name: "Retired", id: 2, color: "redColor" },
  { name: "Left", id: 3, color: "redColor" },
  { name: "Disabled", id: 4, color: "redColor" },
];

export const complaintsummaryDatetypelst = [
  { name: "Service Date", id: 4 },
  { name: "Apply date", id: 5 },
  { name: "Complaint Date", id: 12 }
];

export const generalComplaintsummaryDatetypelst = [
  { name: "Apply date", id: 5 },
  { name: "Complaint Date", id: 12 }
];

export const complaintAdvanceSearchtypelst = [
  { name: "Product ID", id: 7 },
  { name: "PO No", id: 9 }

];
export enum FBRedirectionType {
  Mission = 1,
  Report = 2
}
export const dashboardSupplierNameCodeList = [
  { name: "Supplier Name", id: 13 },
  { name: "Code", id: 14 }
];

export const InvoiceStatusSummaryStatusList = [
  { id: 1, name: "Pending" },
  { id: 2, name: "Invoiced" }
];

export const LinkAppServiceList = [{ id: 1, name: "Inspection" },
{ id: 2, name: "Audit" }, { id: 3, name: "TCF" }, { id: 4, name: "Lab Testing" }];

export const TermList = [{ id: 1, name: 'Last 1 Week' }, { id: 2, name: 'Last 1 Month' },
{ id: 3, name: 'Last 1 Year' }, { id: 4, name: 'Custom' }];

export const TCFTermList = [{ id: 1, name: '1 Year' }, { id: 2, name: '6 Month' },
{ id: 3, name: '3 Month' }, { id: 5, name: 'Custom' }];

export const SupplierScoreTermList = [{ id: 1, name: '10 days' }, { id: 2, name: '3 Months' },
{ id: 3, name: '6 Months' }, { id: 6, name: 'Custom' }];

export const ServiceImageList = [
  {
    "serviceId": APIService.Inspection, "entityId": EntityAccess.API,
    "serviceEnabledFileName": "inspection-navigation.svg",
    "serviceDisabledFileName": "inspection-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Audit, "entityId": EntityAccess.API,
    "serviceEnabledFileName": "audit-navigation.svg",
    "serviceDisabledFileName": "audit-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Tcf, "entityId": EntityAccess.API,
    "serviceEnabledFileName": "tcf-navigation.svg",
    "serviceDisabledFileName": "tcf-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.LabTesting, "entityId": EntityAccess.API,
    "serviceEnabledFileName": "lab-navigation.svg",
    "serviceDisabledFileName": "lab-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Inspection, "entityId": EntityAccess.SGT,
    "serviceEnabledFileName": "inspection-navigation.svg",
    "serviceDisabledFileName": "inspection-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Audit, "entityId": EntityAccess.SGT,
    "serviceEnabledFileName": "audit-navigation.svg",
    "serviceDisabledFileName": "audit-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Tcf, "entityId": EntityAccess.SGT,
    "serviceEnabledFileName": "tcf-navigation.svg",
    "serviceDisabledFileName": "tcf-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.LabTesting, "entityId": EntityAccess.SGT,
    "serviceEnabledFileName": "lab-navigation.svg",
    "serviceDisabledFileName": "lab-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Inspection, "entityId": EntityAccess.AQF,
    "serviceEnabledFileName": "inspection-navigation.svg",
    "serviceDisabledFileName": "inspection-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Audit, "entityId": EntityAccess.AQF,
    "serviceEnabledFileName": "audit-navigation.svg",
    "serviceDisabledFileName": "audit-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.Tcf, "entityId": EntityAccess.AQF,
    "serviceEnabledFileName": "tcf-navigation.svg",
    "serviceDisabledFileName": "tcf-navigation-disabled.svg"
  },
  {
    "serviceId": APIService.LabTesting, "entityId": EntityAccess.AQF,
    "serviceEnabledFileName": "lab-navigation.svg",
    "serviceDisabledFileName": "lab-navigation-disabled.svg"
  }];

export enum RightTypeEnum {
  Inspection = 1,
  Audit = 2,
  TCF = 3,
  LabTesting = 4,
  Customer = 5,
  Vendor = 6,
  HumanResource = 7,
  Document = 8,
  Admin = 9
}

export const RightTypeImageList = [
  {
    "rightTypeId": RightTypeEnum.Inspection, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "inspection-navigation.svg",
    "rightTypeDisabledFileName": "inspection-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Audit, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "audit-navigation.svg",
    "rightTypeDisabledFileName": "audit-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.TCF, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "tcf-navigation.svg",
    "rightTypeDisabledFileName": "tcf-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.LabTesting, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "lab-navigation.svg",
    "rightTypeDisabledFileName": "lab-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Customer, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "customer-navigation.svg",
    "rightTypeDisabledFileName": "customer-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Vendor, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "vendor-navigation.svg",
    "rightTypeDisabledFileName": "vendor-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.HumanResource, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "human-resources-navigation.svg",
    "rightTypeDisabledFileName": "human-resources-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Document, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "document-management-navigation.svg",
    "rightTypeDisabledFileName": "document-management-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Admin, "entityId": EntityAccess.API,
    "rightTypeEnabledFileName": "admin-navigation.svg",
    "rightTypeDisabledFileName": "admin-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Inspection, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "inspection-navigation.svg",
    "rightTypeDisabledFileName": "inspection-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Audit, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "audit-navigation.svg",
    "rightTypeDisabledFileName": "audit-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.TCF, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "tcf-navigation.svg",
    "rightTypeDisabledFileName": "tcf-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.LabTesting, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "lab-navigation.svg",
    "rightTypeDisabledFileName": "lab-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Customer, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "customer-navigation.svg",
    "rightTypeDisabledFileName": "customer-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Vendor, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "vendor-navigation.svg",
    "rightTypeDisabledFileName": "vendor-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.HumanResource, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "human-resources-navigation.svg",
    "rightTypeDisabledFileName": "human-resources-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Document, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "document-management-navigation.svg",
    "rightTypeDisabledFileName": "document-management-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Admin, "entityId": EntityAccess.SGT,
    "rightTypeEnabledFileName": "admin-navigation.svg",
    "rightTypeDisabledFileName": "admin-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Inspection, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "inspection-navigation.svg",
    "rightTypeDisabledFileName": "inspection-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Audit, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "audit-navigation.svg",
    "rightTypeDisabledFileName": "audit-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.TCF, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "tcf-navigation.svg",
    "rightTypeDisabledFileName": "tcf-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.LabTesting, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "lab-navigation.svg",
    "rightTypeDisabledFileName": "lab-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Customer, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "customer-navigation.svg",
    "rightTypeDisabledFileName": "customer-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Vendor, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "vendor-navigation.svg",
    "rightTypeDisabledFileName": "vendor-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.HumanResource, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "human-resources-navigation.svg",
    "rightTypeDisabledFileName": "human-resources-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Document, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "document-management-navigation.svg",
    "rightTypeDisabledFileName": "document-management-navigation-disabled.svg"
  },
  {
    "rightTypeId": RightTypeEnum.Admin, "entityId": EntityAccess.AQF,
    "rightTypeEnabledFileName": "admin-navigation.svg",
    "rightTypeDisabledFileName": "admin-navigation-disabled.svg"
  }];

export const travelMatrixSearchTypeList = [
  { name: "City", id: 1 },
  { name: "County", id: 2 }
];


export enum TravelMatrixLevel {
  City = 1,
  County = 2
}

export const categorytypelst = [
  { name: "Category", id: 1 },
  { name: "Sub", id: 2 },
  { name: "Sub 2", id: 3 },
  { name: "Sub 3", id: 4 }
];

export const GroupByFilter = [
  { name: "Country", id: 1 },
  { name: "Supplier", id: 2 },
  { name: "Factory", id: 3 },
  { name: "Brand", id: 4 },
];

export const ResultTypeFilterDdl = [
  { name: "Report Result", id: 1 },
  { name: "Customer Result", id: 2 }
];

export const CategoryFilterDdl = [
  { name: "Mapped", id: 1 },
  { name: "Not Mapped", id: 2 }
]

export enum ExpenseTypeEnum {
  MandayCost = 21,
  FoodAllowance = 25
}


export const pendingClaimSearchTypeLst = [
  { name: "Booking No", id: 1 },
  { name: "Claim No", id: 16 }
]



export const MandayCompanyByList = [
  { id: 1, name: "1 Month" },
  { id: 3, name: "3 Month" },
  { id: 6, name: "6 Month" },
  { id: 12, name: "12 Month" },
];

export const MandayDropDown = [
  { id: 1, name: 'Estimated Man Day' },
  { id: 2, name: 'Actual Man Day' }
];

export enum MandayType {
  EstimatedManDay = 1,
  ActualManDay = 2
}

export enum GeneralDefaultDateType {
  ApplyDate = 5
}

export const NotificationList: DataSource[] = [
  { id: 25, name: 'Customer Service' },
  { id: 20, name: 'Quotation Create' },
  { id: 27, name: 'Quotation Send' },
  { id: 22, name: 'Quotation Validate' },
  { id: 35, name: 'Report Checker' },
  { id: 24, name: 'Inspection Confirmed' },
  { id: 26, name: 'Inspection Scheduled' }
]

export const appThemeList = [
  { name: "sgt-theme", id: EntityAccess.SGT },
  { name: "aqf-theme", id: EntityAccess.AQF }
];

export enum theme {
  light = 1,
  dark = 2
}

export const themeList = [
  { name: "light", id: theme.light },
  { name: "dark", id: theme.dark }
];

export const creditNoteSearchTyplst = [
  { name: "Credit No", id: 1, shortName: "CE#" },
]

export enum InvoicePreviewType {
  Booking = 1,
  Product = 2,
  SimpleInvoice = 3,
  PO = 4,
  InvoiceExtraFee = 5,
  Audit = 6,
  ManualInvoice = 7,
  CreditNote = 8,
  InspectionReport = 9
}

export enum SaveCustomerResult {
  Success = 1,
  CustomerIsNotSaved = 2,
  CustomerIsNotFound = 3,
  CustomerExists = 4,
  CustomerAddressNotFound = 5,
  DuplicateCustomerNameFound = 6,
  DuplicateGLCodeFound = 7,
  DuplicateEmailFound = 8,
  CustomErrorFound = 9,
  CustomerOneBrandRequired = 10,
  CustomerEntiyNotRemoved = 11
}

export enum SaveCustomerBuyerResult {
  Success = 1,
  CustomerBuyerIsNotSaved = 2,
  CustomerBuyerIsNotFound = 3,
  CustomerBuyerExists = 4
}

export enum SaveCustomerDepartmentResult {
  Success = 1,
  CustomerDepartmentIsNotSaved = 2,
  CustomerDepartmentIsNotFound = 3,
  CustomerDepartmentExists = 4
}

export const reportSearchTypeLst = [
  { name: "Inspection No", id: 1, shortName: "IN#" },
  { name: "PO No", id: 3, shortName: "PO#" },
  { name: "Customer Booking No", id: 8, shortName: "CU#" },
  { name: "Product Ref", id: 17, shortName: "PR#" },
  { name: "Report No", id: 17, shortName: "RE#" }
];

export const scheduleSummaryExportTypeList = [
  { name: "QC Level", id: 1 },
  { name: "Product Ref. Level", id: 2 },
  { name: "Report Level", id: 3 }
];


export enum ScheduleSummaryExportType {
  QCLevel = 1,
  ProductRefLevel = 2,
  ReportLevel = 3
}
export enum ResetPasswordResult {
  Success = 1,
  PasswordNotSaved = 2,
  PasswordNotMatch = 3,
  InvalidPassword = 4
}

export const supplierTypeList = [
  { name: "Supplier Name", id: 13 },
  { name: "Supplier Code", id: 14 },
];


export const uploadPOErrorMessages = [
  { name: "Purchase Order Number is mandatory", id: 1 },
  { name: "Product Reference is mandatory", id: 2 },
  { name: "Quanity is mandatory", id: 3 },
  { name: "Etd is not in valid date format(DD/MM/YYYY)", id: 4 },
  { name: "Given Country Data is not available", id: 5 },
  { name: "Po Number Already Exists", id: 6 },
  { name: "Invalid Quantity Data", id: 7 },
  { name: "Product Description is mandatory", id: 8 },
  { name: "Quantity value should be greater than zero", id: 9 },
  { name: "Duplicate Po Products Found", id: 10 },
  { name: "Barcode character Limit Exceeded" + barCodeCharacterLimit.toString(), id: 13 },
  { name: "Color Code is mandatory", id: 14 },
  { name: "Color Name is mandatory", id: 15 }
];
export const DefaultExchangeRate = 1;


export enum FileAttachmentCategory {
  General = 1,
  Gap = 2
}

export enum PaymentOptions {
  A = 1,
  B = 2
}

export enum BookingTypes {
  Announced = 1,
  UnAnnounced = 2
}

export enum AuditTypes {
  Announced = 1,
  SemiAnnounced = 2,
  UnAnnounced = 3
}

export enum CustomerEnum {
  Gap = 568
}
export const expenseClaimStatus = [
  { name: "Pending", id: 1 },
  { name: "Approved", id: 2 },
  { name: "Rejected", id: 3 },
  { name: "Paid", id: 4 },
  { name: "Checked", id: 5 },
  { name: "Cancelled", id: 6 },
];
export const inspectionSearchlst = [
  { name: "Inspection No", id: 1, shortName: "IN#" }
];

export const expenseSummaryExportTypeList = [
  { name: "Expense Summary KPI", id: 1 },
  { name: "Expense Detail KPI", id: 2 }
];

export enum ExpenseSummaryExportType {
  ExpenseSummaryKPI = 1,
  ExpenseDetailKPI = 2
}

//OrderStatus -36,ScheduleAnalysis-38,InspectionSummaryQC-41, CustomerMandayKPITemplate=42,XeroKPIInvoiceTemplate=44,XeroKPIExpenseTemplate=45,AuditSummaryQC = 51, OutstandingInvoice = 55
export const CommonKpiTemplate = [
  36, 38, 41, CustomerMandayKPITemplate, 43, ARFollowUpReportKPITemplate, XeroKPIInvoiceTemplate, XeroKPIExpenseTemplate, 49, 50, 51, 55
];

// Flash Process Audit = 47, Gap Audit = 48, Audit Schedule Analysis = 50, Audit Summary - QC = 51
export const AuditKpiTemplate = [
  47, 48, 50, 51
]

export const CustomerMandayGroupByFilter = [
  { name: "Customer", id: 1 },
  { name: "ServiceType", id: 2 },
  { name: "Factory Country", id: 3 },
  { name: "Factory Province", id: 4 },
  { name: "Factory City", id: 5 }
];

// Inspection -1,Audit -2,TCF -3 ,LabTesting -4, Customer -5, Supplier/Factory -6, HumanResource -7, Document -8
export const OutSourceUserType = [
  1, 2, 3, 4, 5, 6, 7, 8
]

export const UserRightsList = [
  { name: "Edit", id: 1 },
  { name: "Delete", id: 2 },
  { name: "Upload", id: 3 },
  { name: "Download", id: 4 }
];

export const DACorrelationList = [
  { name: "Yes", id: 1 },
  { name: "No", id: 2 }
];

export const ClaimSearchTypeList = [
  { name: "Inspection No", id: 1, shortName: "IN#" },
  { name: "PO No", id: 3, shortName: "PO#" },
  { name: "Product Reference", id: 17, shortName: "PR#" }
];

export enum DACorrelationEnum {
  yes = 1,
  no = 2
}

export enum DFDDLSourceType {
  ECIBDM = 1,
  ECIOffice = 2,
  ECIQCMName = 3,
  ECIPartialShipment = 4,
  ECIPrepack = 5,
  GifiOffice = 6,
  GAPSnugFit = 7,
  GAPInspectionPlatform = 8,
  PaymentOption = 9,
  GAPAuditProductCategory = 10
}

export enum TripType {
  NoTrip = 1,
  SingleTrip = 2,
  DoubleTrip = 3
}

export enum EmailTypeEnum {
  CustomerDecision = 1,
  ReportSend = 2,
  InvoiceSend = 3
}

export const KpiInvoicePaymentStatuslst = [
  { name: "Pending", id: 1 },
  { name: "Paid", id: 2 }
];

export const PendingPaymentStatus = 1;

export enum CityType {
  StartCity = 1,
  DestCity = 2
}

export enum ExpenseClaimTypeEnum {
  Audit = 1,
  Inspection = 2,
  NonInspection = 3
}

export enum KPIECIRResponseResult {
  Success = 1,
  RowsDataNotMoreThan50000 = 2,
  SheetNotFound = 3
}

export const manualInvoiceSearchtypelst = [
  { name: "Booking No", id: 1, shortName: "IN#" },
  { name: "Audit No", id: 19, shortName: "AUD#" },
  { name: "Invoice No", id: 10, shortName: "INV#" }
];

export const manualInvoiceDateTypelst = [
  { name: "Apply Date", id: 5 },
  { name: "Service Date", id: 4 },
  { name: "Invoice Date", id: 11 }
];

export const InspectionTermList = [{ id: 1, name: 'Last 7 days' }, { id: 2, name: 'Last 30 days' },
{ id: 3, name: 'Month to date' }, { id: 4, name: 'Year to date' }, { id: 5, name: 'Next 15 days' },
{ id: 6, name: 'Next 30 days' }, { id: 7, name: 'Custom' }];

export enum InspectionTermEnum {
  Last7days = 1,
  Last30days = 2,
  Monthtodate = 3,
  Yeartodate = 4,
  Next15days = 5,
  Next30days = 6,
  Custom = 7
}

export const regulationdatetypelst = [

  { name: "Inforce Date", id: 1 },
  { name: "Discard Date", id: 2 }
];

export enum DefaultRegulationDateType {
  InforceDate = 1
}

export const tcfCurrentList = [

  { name: "Yes", id: true },
  { name: "No", id: false }
];

export const standarddatetypelst = [

  { name: "Start Date", id: 1 },
  { name: "Expiry Date", id: 2 },
  { name: "Discard Date", id: 3 }
];

export enum DefaultStandardDateType {
  StartDate = 1
}

export const tcfScopeReqAsPerProject = [
  { name: "Same As Template", id: true },
  { name: "Defined below", id: false }
];

export const tcfScopeBoolValues = [
  { name: "Yes", id: true },
  { name: "No", id: false }
];

export enum PFREFPages {
  InspectionSummary = 1,
  InspectionReportSummary = 2,
  TCFQuotationSummary = 3,
  TCFLandingDashboard = 4,
  TCFSupplierScoreCard = 5,
  LabLandingDashboard = 6
}

export enum TCFScoreCardSort {
  Supplier = 1,
  TCFCompleted = 2,
  TCFToBeFinalized = 3,
  TCFInProgress = 4,
  TCFNew = 5,
  DocsSubmitted = 6,
  DocsWaiting = 7
}

export enum InvoiceFileTypeEnum {
  Invoice = 1,
  ManualInvoice = 2,
  CreditNote = 3
}

export enum SendManualInvoiceResult {
  Success = 1,
  InternalContactRequired = 2,
  EmailSendFailure = 3,
  InvoiceDataNotFound = 4
}

export enum SendExtraFeeInvoiceResult {
  Success = 1,
  CSContactRequired = 2,
  AccountContactRequired = 3,
  EmailSendFailure = 4,
  InvoiceDataNotFound = 5
}

export enum TravelTariffLevelEnum {
  County = 1,
  Town = 2
}
export enum PFREFDateFilter {
  Last30Days = 1,
  Last7Days = 2,
  Next15Days = 3,
  YTD = 4,
  MTD = 5,
  Custom = 6,
  Next30days = 7,
  Next60days = 8,
  Last3Months = 9,
  Last6Months = 10,
  Oneyear = 11
}

export enum TCFQuotationStatus {
  New = 1,
  Cancelled = 2,
  Approved = 3,
}

export enum TCFTemplateStatus {
  New = 1,
  Pending = 2,
  InProgress = 3,
  DocReady = 4,
  ToBeFinalized = 5,
  Completed = 6
}

export enum TCFScoreCardSortedBy {
  Asc = 1,
  Desc = 2
}

export enum CustomTable {
  VendorSummary = 1,
  InspectionSummary = 2,
  QuotationSummary = 3,
  CustomerDecisionSummary = 4,
  InspectionReportSummary = 5,
  InvoiceSummary = 6,
  ExtraFeeSummary = 7,
  EditInvoiceSummary = 8,
  InvoiceStatusSummary = 9,
  ScheduleSummary = 10,
  ExpenseClaimSummary = 11,
  AuditSummary = 12,
  AuditReportSummary = 13,
  FillingAndReviewSummary = 14,
  EmailRuleSummary = 15,
  ReportSendSummary = 16,
  StaffSummary = 17,
  CustomerProductSummary = 18,
  CustomerPriceCardSummary = 19,
  DataManagementSummary = 20,
  CustomerSummary = 21,
  CreditNoteSummary = 22,
  ClaimSummary = 23,
  ComplaintSummary = 24,
  CustomerServiceAllocation = 25,
  ManualInvoiceSummary = 26,
  LabTracking = 27,  
  BoarderLineAnalysis = 28,
  TcfBookingSummary = 29
}

export const TcfDocumentUnderReviewId: number = 5;

export const LandingRightTypeImageList = [
  {
    rightTypeId: RightTypeEnum.Inspection,
    imagePath: 'assets/images/api-inspection.svg'
  },
  {
    rightTypeId: RightTypeEnum.Audit,
    imagePath: 'assets/images/sgt-audit-card.svg'
  },
  {
    rightTypeId: RightTypeEnum.TCF,
    imagePath: 'assets/images/tcf-card.svg'
  },
  {
    rightTypeId: RightTypeEnum.LabTesting,
    imagePath: 'assets/images/lab-test-card.svg'
  },
  {
    rightTypeId: RightTypeEnum.Customer,
    imagePath: 'assets/images/new-set/api/light/customer-navigation.svg'
  },
  {
    rightTypeId: RightTypeEnum.Vendor,
    imagePath: 'assets/images/new-set/api/light/vendor-navigation.svg'
  },
  {
    rightTypeId: RightTypeEnum.HumanResource,
    imagePath: 'assets/images/new-set/api/light/human-resources-navigation.svg'
  },
  {
    rightTypeId: RightTypeEnum.Document,
    imagePath: 'assets/images/new-set/api/light/document-management-navigation.svg'
  },
  {
    rightTypeId: RightTypeEnum.Admin,
    imagePath: 'assets/images/new-set/api/light/admin-navigation.svg'
  },
];

export const LandingRightTypeSubtitleList = [
  {
    rightTypeId: RightTypeEnum.Inspection,
    subtitle: 'HEADER.LBL_INSPECTION_SUB_TITLE'
  },
  {
    rightTypeId: RightTypeEnum.Audit,
    subtitle: 'HEADER.LBL_AUDIT_SUB_TITLE'
  },
  {
    rightTypeId: RightTypeEnum.TCF,
    subtitle: 'HEADER.LBL_TCF_SUB_TITLE'
  },
  {
    rightTypeId: RightTypeEnum.LabTesting,
    subtitle: 'HEADER.LBL_LAB_SUB_TITLE'
  },
  {
    rightTypeId: RightTypeEnum.Customer,
    subtitle: 'Customer'
  },
  {
    rightTypeId: RightTypeEnum.Vendor,
    subtitle: 'Vendor'
  },
  {
    rightTypeId: RightTypeEnum.HumanResource,
    subtitle: 'HumanResource'
  },
  {
    rightTypeId: RightTypeEnum.Document,
    subtitle: 'Document'
  },
  {
    rightTypeId: RightTypeEnum.Admin,
    subtitle: 'Admin'
  },
];

export const AccountingRoles = [RoleEnum.Accounting];

export const customerProductSearchtypelst = [
  { name: "Product Ref", id: 17, shortName: "PR#" },
  { name: "Product Ref Desc", id: 20, shortName: "PRDESC#" },
  { name: "Factory Reference", id: 21, shortName: "FR#" }
];


export const PaidPaymentStatus = [InvoicePaymentStatus.Paid, InvoicePaymentStatus.WriteOff, InvoicePaymentStatus.Waived];

export const NotPaidPaymentStatus = [InvoicePaymentStatus.NotPaid, InvoicePaymentStatus.HalfPaid, InvoicePaymentStatus.ChequeReceived];

export const factoryTypeList = [
  { name: "Factory Name", id: 13 },
  { name: "Factory Code", id: 14 },
];
export const InternalUsers = [UserType.InternalUser, UserType.OutSource];
